import React from 'react';
import { Container } from 'react-bootstrap';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { Input, Select } from '../Functions/Form';
import MessageBox from '../Functions/MessageBox';
import { resetForm } from '../Functions/Utilities';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Buttons from '../Functions/Buttons';

export const Footer = () => {

    const hubSpotPortalId = '23352734';
    const hubSpotFormId = '0907cd39-3d20-4bc0-b0a5-38ad854d9a53';

    const hubLocalSpotPortalId = '45278723';
    const hubLocalSpotFormId = '62ae773d-fe28-4eb7-be1f-211d3c68dacf';


    const submitDataToHubSpot = async (data, actions) => {
        var hubSpotUrl = '';
        if (window.location.origin.includes('localhost') || window.location.origin.includes('10.200.0.78')) {
            //hubSpotUrl = `https://forms.hubspot.com/uploads/form/v2/${hubSpotPortalId}/${hubSpotFormId}`;
            hubSpotUrl = `https://forms.hubspot.com/uploads/form/v2/${hubLocalSpotPortalId}/${hubLocalSpotFormId}`;
        } else {
            hubSpotUrl = `https://forms.hubspot.com/uploads/form/v2/${hubSpotPortalId}/${hubSpotFormId}`;
        }
        const formData = new FormData();

        formData.append("email", data.email);
        formData.append("select_your_role", data.role);

        await fetch(hubSpotUrl, {
            method: 'post',
            body: formData
        }).then(res => {
            if (res.status === 204) {
                resetForm(actions);
            }
        })
    }
    return (
        <section className="py-[100px] text-left overflow-hidden md:py-[85px] sm:py-[60px] footer-styles border-top">
            <Container>
                <div class="row">
                    <div class="footer-menu col-md-3 col-sm-6">
                        <img className="default-logo" width="166" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                    </div>
                    <div class="footer-menu col-md-2 col-sm-6 sm-mb-10">
                        <span class="mb-[10px] block list-title xs:!mb-[10px]">Looq AI</span>
                        <ul>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`${window.location.origin}`}>Home</Link></li>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`${window.location.origin}/AboutUs`} >About Us</Link></li>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`${window.location.origin}/NewsAndBlog`}>News/Blog</Link></li>
                        </ul>
                    </div>
                    <div class="footer-menu col-md-2 col-sm-6 sm-mb-10">
                        <span class="mb-[10px] block list-title xs:!mb-[10px]">Contact</span>
                        <ul>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`mailto:info@looq.ai`} target='_blank'>Email</Link></li>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`https://www.linkedin.com/company/looqai/`} target='_blank'>LinkedIn</Link></li>
                            {/* <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`tel:8882094958`} target='_blank'>Phone Number</Link></li> */}
                        </ul>
                    </div>
                    <div class="footer-menu col-md-2 col-sm-6 sm-mb-10">
                        <span class="mb-[10px] block list-title xs:!mb-[10px]"><Link aria-label="footer menu link" to={`${window.location.origin}/Looq TERMS OF USE.pdf`} style={{
                            textDecoration: 'underline'
                        }} target='_blank'>Legal</Link></span>
                        <ul>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`${window.location.origin}/Looq Privacy Policy.pdf`} target='_blank'>Privacy Policy</Link></li>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`${window.location.origin}/Looq Terms and Conditions.pdf`} target='_blank'>Terms & Conditions</Link></li>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={`${window.location.origin}/Looq Platform Services Agreement.pdf`} target='_blank'>Platform Services Agreement</Link></li>
                        </ul>
                    </div>
                    <div class="footer-menu col-md-3 col-sm-6">
                        <span class="mb-[10px] block list-title xs:!mb-[10px]">Updates</span>
                        <ul>
                            <li class="mb-[7px] last:mb-0"><Link aria-label="footer menu link">Sign up for the latest news</Link></li>
                        </ul>
                        {/* <ul>
                            <li class="mb-[7px] last:mb-0"><input type='text' placeholder='Email Address'></input>Submit</li>
                        </ul> */}
                        <div md={8} className="flex justify-center">
                            <Formik
                                initialValues={{ email: '', role: '' }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email("Invalid email.").required("Field is required."),
                                    role: Yup.string().required("Field is required.")
                                })}
                                onSubmit={async (values, actions) => {
                                    actions.setSubmitting(true)
                                    // const response = await sendEmail(values)
                                    // response.status === "success" && resetForm(actions)
                                    // actions.setSubmitting(true)
                                    await submitDataToHubSpot(values, actions)
                                }}
                            >
                                {({ isSubmitting, status }) => (
                                    <div className=" w-full footer-parent-sec relative">
                                        <Form >
                                            <Input showErrorMsg={false} type="email" name="email" className="footer-email" placeholder="Email Address" />
                                            <Select name="role" className='footer-dropdown'>
                                                <option value="">Select Role</option>
                                                <option value="Utilities">Utilities</option>
                                                <option value="Engineering/Construction Company">Engineering/Construction Company</option>
                                                <option value="Surveying">Surveying</option>
                                                <option value="Investor">Investor</option>
                                                <option value="Partner">Partner</option>
                                                <option value="Other">Other</option>
                                            </Select>
                                            <Buttons type="submit" className={`footer-button font-medium font-Roboto-Condensed${isSubmitting ? " loading" : ""}`} color="#fff" title="Sign Up" />

                                        </Form>
                                        <AnimatePresence>
                                            {status && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="absolute top-[115%] left-0 w-full">
                                                <MessageBox className="py-[5px] rounded-[100px] text-center z-[1]" theme="message-box01" variant="success" message="Your message has been sent successfully subscribed to our email list!" />
                                            </motion.div>}
                                        </AnimatePresence>
                                    </div>
                                )}
                            </Formik>
                        </div>

                    </div>
                </div>
            </Container>
        </section>
    )
}