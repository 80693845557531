import React, { memo } from "react";
import { Link } from "react-router-dom";

// Libraries
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";
//import Pagination from "./HelperComponents/Pagination";


// Data
//import { authorData } from "./BlogData";


const blogData = [
    {
        id: 1,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/geo_week.png",
        title: "Looq AI Emerges From Stealth With Release of Looq platform",
        content: "Looq AI announced the launch of their Looq platform, integrating AI-enabled digital twin technology with their proprietary camera technology for large-scale data analysis and visualization, primarily targeting the electric utility industry.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "5 March 2024",
        link: "https://www.geoweeknews.com/news/looq-ai-digital-twin-data-capture-artificial-intelligence-imagery"

    },
    {
        id: 2,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/kevin.png",
        title: "Kevin O’Donovan interviews CEO and Co-Founder Dominique Meyer at DistribuTECH",
        content: "Kevin O’Donovan interviews CEO and Co-Founder Dominique Meyer to discuss Looq’s core functionalities and how they can be applied to the power utilities industry.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "29 February 2024",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7171202708186558465/"

    },
    {
        id: 3,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/iel.png",
        title: "Incubatenergy® Labs Selects Looq AI for Demonstration Pitch Day",
        content: "Looq AI is among the 20 clean energy startups selected by EPRI's Incubatenergy® Labs from over 200 applicants to pitch pilot project concepts at the Electrification 2024 International Conference in Savannah, Georgia.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "27 February 2024",
        link: "https://www.epri.com/about/media-resources/press-release/tAZ6ZGzow1MoZ2whf5hrCOhoZMdycueg"

    },
    {
        id: 4,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/destributech.png",
        title: "Looq AI Launches AI-Enabled Digital Twin Platform at DistribuTECH 2024",
        content: "Looq AI has successfully secured $2.6M in a preferred series seed funding led by BootstrapLabs, a VC firm focused on applied AI. To date, Looq AI has raise a total of $3.75M.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "27 February 2024",
        link: `${window.location.origin}/LOOQ_Letterhead_Press_Release_Launch Looq Platform Final_v1.pdf`

    },
    {
        id: 5,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/nrel.png",
        title: "Looq AI Chosen for 2024 NREL Industry Growth Forum",
        content: "The 2024 NREL Industry Growth Forum features 40 innovative presenters from across the clean-tech sector, and Looq AI has been chosen for this years list.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "20 February 2024",
        link: "https://www.nrel.gov/news/press/2024/40-presenters-for-2024-nrel-industry-growth-forum-aim-to-break-ground-in-cleantech.html"

    },
    {
        id: 6,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/dom_sanjayGangal.png",
        title: "Sanjay Gangal of AECCafe.com interviews CEO and Co-Founder Dominique Meyer",
        content: "Sanjay Gangal interviews CEO and Co-Founder Dominique Meyer to discuss Looq’s objective, benefits and future goals.",
        author: 3,
        likes: 25,
        comments: 0,
        date: "19 February 2024",
        link: "https://aeccafe.com/video/Looq-AI-Dominique-Meyer-CEO/1895680/media.html"

    },
    {
        id: 7,
        category: ["CREATIVE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/blog-1.png",
        title: "Looq AI included in Black & Veatch’s IgniteX Climate Tech Accelerator",
        content: "IgniteX empowers startups offering scalable climate solutions for critical infrastructure. The seven selected program participants will partner with Black & Veatch subject matter leaders to co-develop, pilot and market their new technologies.",
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 JULY 2023",
        link: "https://www.businesswire.com/news/home/20230713635784/en/Black-Veatch%E2%80%99s-IgniteX-Climate-Tech-Accelerator-Program-Announces-2023-Participants"

    },
    {
        id: 8,
        category: ["BUSINESS"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/blog-2.png",
        title: "Southern California Energy Innovation Network (SCEIN) Accelerator",
        content: "Cleantech San Diego is a member-based business association that positions the greater San Diego region as a global leader in the Cleantech economy.",
        author: 2,
        likes: 25,
        comments: 0,
        //  date: "25 FEBRUARY 2020",
        link: "https://cleantechsandiego.org/scein/"

    },
    {
        id: 9,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "assets/img/news-blog/blog-3.png",
        title: "Looq wins Gold in the category “Critical Human Infastructure” at Edison Awards",
        content: "The Edison Awards honor excellence in new product and service development, marketing, human-centered design and innovation.",
        author: 3,
        likes: 25,
        comments: 0,
        //  date: "30 JANUARY 2020",
        link: "https://edisonawards.com/2023-winners/"

    }
]

export const authorData = [
    {
        id: 1,
        img: "assets/img/news-blog/Ellipse.png",
        name: "Ariyella David",
        // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        // designation: "co-founder"
    },
    {
        id: 2,
        img: "assets/img/news-blog/Ellipse.png",
        name: "Ariyella David",
        // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        // designation: "co-founder"
    },
    {
        id: 3,
        img: "assets/img/news-blog/Ellipse.png",
        name: "Ariyella David",
        // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        // designation: "co-founder"
    }
]


const NewsAndBlogsData = (props) => {
    return (
        <div>
            {
                props.data.map((item, i) => {
                    return (
                        <motion.div key={i} className={`blog-sideimage`} {...props.animation}>
                            <div className="blog-post-image">
                                <Link aria-label="link" to={`${item.link}`} target="_blank">
                                    <img height="" width="" alt="blogimage" src={item.img} />
                                </Link>

                            </div>
                            <div className="post-details">
                                <span className="blog-post-date"> {item.date} </span>
                                <Link aria-label="link" to={`${item.link}`} target="_blank" className="blg-post-title"> {item.title} </Link>
                                {/* <span className="blg-post-title"> {item.title} </span> */}
                                <p>{item.content}</p>
                                <div className="blog-author">
                                    <img height="" width="" className="blog-author-img" src={authorData.filter(author => author.id === item.author)[0].img} alt="author" />
                                    <span>
                                        <span> Posted By
                                            <span className="blog-author-name"> {authorData.filter(author => author.id === item.author)[0].name} </span>
                                        </span>
                                        {/* <span className="inline-block"> IN
                                            <Link aria-label="link" to={`/blogs/category/${[item.category[0].toString().split(" ").join("").toLowerCase()]}`} className="blog-author-catagory text-darkgray ml-[4px]" >
                                                {item.category[0]}
                                            </Link>
                                        </span> */}
                                    </span>
                                </div>
                            </div>
                        </motion.div>
                    );
                })
            }
            {/* {props.pagination === true && (
                <div className="flex justify-center mt-[7.5rem] md:mt-20">
                    <Pagination />
                </div>
            )} */}
        </div>
    );
};

NewsAndBlogsData.defaultProps = {
    data: blogData,
    link: "/blog-types/blog-standard-post/"
};

NewsAndBlogsData.propTypes = {
    grid: PropTypes.string,
    link: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.number,
            category: PropTypes.array,
            tags: PropTypes.array,
            blogType: PropTypes.string,
            img: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            author: PropTypes.number,
            likes: PropTypes.number,
            comments: PropTypes.number,
            date: PropTypes.string,
            double_col: PropTypes.bool
        })
    ),
};

export default memo(NewsAndBlogsData);
