import { Col, Container, Row } from 'react-bootstrap';
import BlogGrid from './BlogGrid';

const blogData = [
    {
        id: 1,
        category: [""],
        blogType: "grid",
        img: "/assets/img/geo_week.png",
        title: "Looq AI Emerges From Stealth With Release of Looq platform",
        content: "Looq AI announced the launch of their Looq platform, integrating AI-enabled digital twin technology with their proprietary camera technology for large-scale data analysis and visualization, primarily targeting the electric utility industry.",
        link: "https://www.geoweeknews.com/news/looq-ai-digital-twin-data-capture-artificial-intelligence-imagery"
    },
    {
        id: 2,
        category: [""],
        blogType: "grid",
        img: "/assets/img/nrel.png",
        title: "Looq AI Chosen for 2024 NREL Industry Growth Forum",
        content: "The 2024 NREL Industry Growth Forum features 40 innovative presenters from across the clean-tech sector, and Looq AI has been chosen for this years list.",
        link: "https://www.nrel.gov/news/press/2024/40-presenters-for-2024-nrel-industry-growth-forum-aim-to-break-ground-in-cleantech.html"
    },
    {
        id: 3,
        category: [""],
        blogType: "grid",
        img: "/assets/img/iel.png",
        title: "Incubatenergy® Labs Selects Looq AI for Demonstration Pitch Day",
        content: "Looq AI is among the 20 clean energy startups selected by EPRI's Incubatenergy® Labs from over 200 applicants to pitch pilot project concepts at the Electrification 2024 International Conference in Savannah, Georgia.",
        link: "https://www.epri.com/about/media-resources/press-release/tAZ6ZGzow1MoZ2whf5hrCOhoZMdycueg"
    },
    {
        id: 4,
        category: [""],
        blogType: "grid",
        img: "/assets/img/dom_sanjayGangal.png",
        title: "Sanjay Gangal of AECCafe.com interviews CEO and Co-Founder Dominique Meyer",
        content: "Sanjay Gangal interviews CEO and Co-Founder Dominique Meyer to discuss Looq’s objective, benefits and future goals.",
        link: "https://aeccafe.com/video/Looq-AI-Dominique-Meyer-CEO/1895680/media.html"
    },
    {
        id: 5,
        category: [""],
        blogType: "grid",
        img: "/assets/img/kevin.png",
        title: "Kevin O’Donovan interviews CEO and Co-Founder Dominique Meyer at DistribuTECH",
        content: "Kevin O’Donovan interviews CEO and Co-Founder Dominique Meyer to discuss Looq’s core functionalities and how they can be applied to the power utilities industry.",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7171202708186558465/"
    },
    {
        id: 6,
        category: [""],
        blogType: "grid",
        img: "/assets/img/destributech.png",
        title: "Looq AI Launches AI-Enabled Digital Twin Platform at DistribuTECH 2024",
        content: "Looq AI has successfully secured $2.6M in a preferred series seed funding led by BootstrapLabs, a VC firm focused on applied AI. To date, Looq AI has raise a total of $3.75M.",
        link: `${window.location.origin}/LOOQ_Letterhead_Press_Release_Launch Looq Platform Final_v1.pdf`
    }
]

export const PopularArticles = () => {
    return (
        <section className="popularartical-bg pb-[160px] lg:pb-[120px] md:pb-[95px] sm:pb-[80px] xs:pb-[50px]">
            <Container>
                <Row className="justify-center text-center mb-3">
                    <Col>
                        <h2 className="font-bold font-size-46 font-Roboto-Condensed color-E0604C">News</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BlogGrid grid="blog-wrapper grid grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large" data={blogData} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}