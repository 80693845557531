
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Technology = () => {

    return (

        <section className="py-[100px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden startup-processstep the-platfrom-bg-img">
            <Container>
                <Row className="items-center justify-center">
                    <Col xl={5} lg={6} md={10}>
                        <h1 className="font-Roboto-Condensed font-medium text-white font-size-80 mb-[60px] sm:mb-[10px] line-height">The <br></br>Platform</h1>
                        <p className="font-Roboto-Condensed text-white font-size-24 line-height-1-5">
                            Looq AI’s solution is an integrated hardware/software platform comprising the Looq Q-Camera, automated cloud processing, and a web-based application for visualization, analysis, collaboration.</p>
                    </Col>
                    <Col className="offset-xl-1 col-lg-6 md:mt-[50px]">

                    </Col>
                    {/* <motion.div className="offset-xl-1 col-lg-6 md:mt-[50px]" {...fadeInLeft}>
                        <div className="-mr-[30vw] relative md:mr-0" >
                            <img src="/assets/img/technology-image.png" width={786} height={726} className="overflow-hidden" alt="" />
                        </div>
                    </motion.div> */}
                </Row>
            </Container>
        </section>
    )

}
export default Technology;