import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SwiperSlide } from "swiper/react";

export const HeaderText = (props) => {
    return (
        <>
            {
                props.showVideo ?
                    // <section className="lg:h-[720px] md:h-[750px] sm:h-[500px]">
                    <section className='video-responsive-intro'>
                        <video muted loop={true} autoPlay={true} playsInline={true} style={{
                            width: '100%'
                        }} src='/assets/img/Media1.mp4' type="video/mp4"></video>
                    </section>
                    :
                    <section className="h-[900px] lg:h-[720px] md:h-[750px] sm:h-[500px]">
                        <SwiperSlide style={{ backgroundImage: `url("/assets/img/news-blog/news-blog-bg.png")` }} className="cover-background overflow-hidden relative">
                            <div className="absolute h-full w-full"></div>
                            <div className="h-full text-center container">
                                <Row className="h-full md:w-full my-0 mx-auto">
                                    <Col className="h-full justify-center flex-col relative flex">
                                        <h2 className="mb-16 Banner-title">News/Blog</h2>
                                    </Col>
                                </Row>
                            </div>
                        </SwiperSlide>
                    </section>
            }
        </>
    )
}
