import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "../Components/Home/LandingPage";
import { ParallaxProvider } from "react-scroll-parallax";
import { NewsAndBlog } from "../Components/Home/NewsAndBlog";
import AboutUs from "../Components/Home/AboutUs";
import DocsRedirect from "../Components/Home/DocsRedirect";
import ScrollToTop from "../Components/ScrollToTop";
import { Dialog, DialogContent, DialogTitle, Button, Checkbox } from "@mui/material";
import Cookies from "js-cookie";
import InvestorDeckPublicRedirect from "../Components/Home/InvestorDeckPublicRedirect";
import DemoRegisterRedirect from "../Components/Home/DemoRegisterRedirect";
import TagManager from "react-gtm-module";

function Routing() {

    const [isCookieDailog, setIsCookieDailog] = useState(false);
    const [isCookieSettings, setIsCookieSettings] = useState(false);

    const [isStrictlyNecessary, setIsStrictlyNecessary] = useState(Cookies.get("Strictly_Necessary"));
    const [isMarketing, setIsMarketing] = useState(Cookies.get("Marketing"));
    const [isAnalytics, setIsAnalytics] = useState(Cookies.get("Analytics"));

    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-MPNLMPST' });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (Cookies.get("Strictly_Necessary") && Cookies.get("Marketing") && Cookies.get("Analytics")) {
                setIsCookieDailog(false);
            } else {
                setIsCookieDailog(true);
            }
        }, 500)
    }, []);

    const twomints = new Date(new Date().getTime() + 60 * 60 * 1000);

    const handleAcceptAllCookies = () => {
        Cookies.set("Strictly_Necessary", true, {
            expires: window.location.origin.includes('localhost') ? twomints : 1,
            secure: true,
            sameSite: 'Strict',
            path: '/'
        });
        Cookies.set("Marketing", true, {
            expires: window.location.origin.includes('localhost') ? twomints : 1,
            secure: true,
            sameSite: 'Strict',
            path: '/'
        });
        Cookies.set("Analytics", true, {
            expires: window.location.origin.includes('localhost') ? twomints : 1,
            secure: true,
            sameSite: 'Strict',
            path: '/'
        });
        setIsCookieDailog(false);
        setIsCookieSettings(false);
    };



    const hadleOnChangeStrictlyNecessary = () => {
        setIsStrictlyNecessary(!isStrictlyNecessary)

    }

    const hadleOnChangeMarketing = () => {
        setIsMarketing(!isMarketing)
    }

    const hadleOnChangeAnalytics = () => {
        setIsAnalytics(!isAnalytics)
    }

    const handleOnAcceptClick = () => {
        if (isStrictlyNecessary) {
            Cookies.set("Strictly_Necessary", true, {
                expires: window.location.origin.includes('localhost') ? twomints : 1,
                secure: true,
                sameSite: 'Strict',
                path: '/'
            });
        }
        if (isMarketing) {
            Cookies.set("Marketing", true, {
                expires: window.location.origin.includes('localhost') ? twomints : 1,
                secure: true,
                sameSite: 'Strict',
                path: '/'
            });
        }
        if (isAnalytics) {
            Cookies.set("Analytics", true, {
                expires: window.location.origin.includes('localhost') ? twomints : 1,
                secure: true,
                sameSite: 'Strict',
                path: '/'
            });
        }
        setIsCookieSettings(false);
        setIsCookieDailog(false);
    }

    return (

        <ParallaxProvider>
            <ScrollToTop />
            <Router>
                <Routes>
                    <Route path="*" element={<LandingPage />} />
                    <Route path="/NewsAndBlog" element={<NewsAndBlog />} />
                    <Route path="/AboutUs" element={<AboutUs />} />
                    <Route path="/docs" element={<DocsRedirect />} />
                    <Route path="/docs2" element={<DocsRedirect />} />
                    <Route path="/investor-deck-public" element={<InvestorDeckPublicRedirect />} />
                    <Route path="/demo" element={<DemoRegisterRedirect />} />
                </Routes>
            </Router>
            <div>
                {isCookieDailog &&
                    <div className='cookie-setting-bg-body'>
                        <div className='flex items-center justify-content-between mb-4'>
                            <h1 className='font-size-36 font-bold font-Roboto-Condensed text-dark mb-0 line-height'>Cookie Settings</h1>
                            <i class="feather-x-circle close-icon" onClick={() => {
                                setIsCookieDailog(false);
                                setIsCookieSettings(false);
                            }}></i>
                        </div>
                        <p className='mb-3'>By clicking “Accept All Cookies”, you agree to the storying of cookies on your device to enhance site navigation, analyze site usage and assist in our marketing efforts.&nbsp;
                            <a aria-label="moreInfo" href={`${window.location.origin}/Looq Privacy Policy.pdf`} target="_blank" rel="noreferrer" className="cookie-more-info"  >More info </a> </p>
                        <div className='flex items-center justify-content-between'>
                            <button className='Accept-All-Cookies-button' onClick={handleAcceptAllCookies}>Accept All Cookies</button>
                            <button className='Cookie-Settings-button' onClick={() => {
                                setIsCookieSettings(true);
                            }}>Cookie Settings</button>
                        </div>
                    </div>
                }
            </div>
            <div>
                {isCookieSettings &&
                    <Dialog
                        open={isCookieSettings}
                        // onClose={handleClose}
                        fullWidth
                        maxWidth='sm'
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        scroll={'paper'}
                        className="Cookies-dailog"
                    >
                        <DialogTitle className="flex items-center justify-content-between w-full pb-4 bg-68C4CE">
                            <div className="font-Roboto-Condensed font-size-26 text-dark text-underline line-height font-weight-normal">
                                Cookie Settings
                            </div>
                            <i class="feather-x-circle close-icon" onClick={() => {
                                setIsCookieSettings(false);
                            }}></i>
                        </DialogTitle>



                        <div>
                            <DialogContent className="w-full p-5">
                                <p className="mb-5">
                                    By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage and assist in our marketing efforts.
                                </p>
                                <div class="flex mb-5">
                                    <div className="mr-2">
                                        <Checkbox
                                            checked={isStrictlyNecessary}
                                            onChange={() => { hadleOnChangeStrictlyNecessary() }}
                                            sx={{
                                                color: '#6CCCD7',
                                                '&.Mui-checked': {
                                                    color: '#6CCCD7',
                                                },
                                            }} />
                                    </div>
                                    <div>
                                        <div className="font-Roboto-Condensed font-size-14 font-bold text-dark">Strictly Necessary (Always  Active)</div>
                                        <p className="font-Roboto-Condensed font-size-14 text-dark font-weight-noraml">
                                            Cookies required to enable basic website functionality
                                        </p>
                                    </div>
                                </div>
                                <div class="flex mb-5">
                                    <div className="mr-2">
                                        <Checkbox
                                            checked={isMarketing}
                                            onChange={() => { hadleOnChangeMarketing() }}
                                            sx={{
                                                color: '#6CCCD7',
                                                '&.Mui-checked': {
                                                    color: '#6CCCD7',
                                                },
                                            }} />
                                    </div>
                                    <div>
                                        <div className="font-Roboto-Condensed font-size-14 font-bold text-dark">Marketing</div>
                                        <p className="font-Roboto-Condensed font-size-14 text-dark font-weight-noraml">
                                            Cookies used to deliver advertising that is more relevant to you and your interests.
                                        </p>
                                    </div>
                                </div>
                                <div class="flex mb-5">
                                    <div className="mr-2">
                                        <Checkbox
                                            checked={isAnalytics}
                                            onChange={() => { hadleOnChangeAnalytics() }}
                                            sx={{
                                                color: '#6CCCD7',
                                                '&.Mui-checked': {
                                                    color: '#6CCCD7',
                                                },
                                            }} />
                                    </div>
                                    <div>
                                        <div className="font-Roboto-Condensed font-size-14 font-bold text-dark">Analytics</div>
                                        <p className="font-Roboto-Condensed font-size-14 text-dark font-weight-noraml">
                                            Cookies helping understand how this website performs, how visitors interact with the site, and whether there may be technical issues.
                                        </p>
                                    </div>
                                </div>

                            </DialogContent>
                        </div>
                        <div className="flex flex-row items-center justify-center w-full mb-8">
                            <Button style={{ marginRight: '10px' }} className="Accept-All-Cookies-button" onClick={() => { handleOnAcceptClick() }} >Accept</Button>
                            <Button className="reject-All-button" onClick={() => {
                                setIsCookieDailog(false);
                                setIsCookieSettings(false);
                            }}>Reject All</Button>
                        </div>
                    </Dialog>
                }
            </div>
        </ParallaxProvider>

    );
}
export default Routing